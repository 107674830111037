var $ = require('./../bower_components/jquery/dist/jquery.js'),
    Hammer = require('./../bower_components/hammerjs/hammer.js'),
    jqHammer = require('./../bower_components/jquery-hammerjs/jquery.hammer.js');


$.fn.html5Slider = function(options) {
    var defaults, Etalage, Slide;

    defaults = $.extend({
        timer: 5,
        auto: true,
        bgimage: true,
        navigation: true,
        transitionTime: 0.5,
        fullScreenImages: true,
        noLabels: false
    }, options);


    Etalage = function(el) {
        this.el = el;
        this.list = this.el.find('> ul');
        this.lists = this.list.find('> li');
        this.View = this.views();

        if (defaults.navigation) {
            if (this.lists.length > 1) {
                this.View.createUI();
            }
            this.arrows = this.el.find('.html5slidernav__arrows');
        }

        this.list = this.el.find('> ul');
        this.lists = this.list.find('> li');

        this.counter = 0;
        this.oldCounter = this.counter;
        this.time = defaults.time;
        this.timer = null;
        this.animating = false,
        this.interval = null;
        this.View.fullImages();
        this.lists.eq(0).addClass('current');
        this.trigger = this.actions();
        this.controllers();
    };


    Etalage.prototype.views = function() {
        var self = this,
            lists = [];

        this.lists.each(function(i, list) {
            var currentClass = i === 0 ? 'current' : '',
                img = typeof $(list).attr('data-img') !== 'undefined' && $(list).attr('data-img').length ?
                    '<span><img src="' + $(list).attr('data-img') + '" /></span>' : 'start';

            if (defaults.noLabels) {
                img = '';
            }
            lists.push('<li class="' + currentClass + ' html5Slider__navlist--item-' + (i + 1) + '">' + (img ? img : '<span>' + (parseInt(i) + 1) + '</span>') + '</li>');
        });
        lists = lists.join('');

        return {
            fullImages: function() {
                self.lists.each(function(li, i) {
                    var img = $(this).find('img'),
                        imgSrc;

                    imgSrc = img.attr('src');
                    if (typeof imgSrc !== 'undefined' && defaults.bgimage) {
                        $(this).attr('style', 'background-image:url("' + imgSrc + '");');
                    }

                });
            },
            createUI: function() {
                self.el.append([
                    '<ol class="html5Slider__navlist">',
                    lists,
                    '</ol>',
                    '<span class="left html5slidernav__arrows nav"><</span><span class="html5slidernav__arrows nav right">></span>'
                ].join(''));
            }
        };
    };


    Etalage.prototype.actions = function() {
        var self = this,
            navList = this.el.find('ol.html5Slider__navlist > li');

        return {
            setToCurrent: function(index) {
                self.animating = true;
                self.counter = index;
                this.setElemsToCurrentSlide();
            },
            isAnimLeft: function() {
                var type = 1;
                type = self.oldCounter > self.counter ? -1 : 1;
                type = self.oldCounter === 0 && self.counter === self.lists.length - 1 ? -1 : type;
                type = self.oldCounter === self.lists.length - 1 && self.counter === 0 ? 1 : type;
                return type;
            },
            setElemsToCurrentSlide: function() {
                var animType = this.isAnimLeft() > 0 ? 'right' : 'left';

                if (typeof navList === 'undefined') {
                    return;
                }
                navList.removeClass('current');
                self.lists.removeClass('current');
                self.lists.removeClass('oldOne');
                self.lists.removeClass('left');
                self.lists.removeClass('right');
                self.lists.addClass(animType);


                setTimeout(function() {
                    navList.eq(self.counter).addClass('current');
                    self.lists.eq(self.counter).addClass('current');
                }, 1);

                if (self.oldCounter !== self.counter) {
                    self.lists.eq(self.oldCounter).addClass('oldOne');

                    if (self.timer !== undefined) {
                        clearTimeout(self.timer);
                    }

                    self.timer = setTimeout(function() {
                        self.oldCounter = self.counter;
                        self.animating = false;
                        self.lists.removeClass('oldOne');
                    }, defaults.transitionTime * 1000);
                }
            },
        };
    };

    Etalage.prototype.controllers = function() {
        var self = this,
            navList = this.el.find('ol li'),
            nextSlide;

        nextSlide = function() {
            if (self.animating) {
                return;
            }
            self.counter = self.counter > self.lists.length - 2 ? 0 : self.counter + 1;
            self.actions().setToCurrent(self.counter);
        };
        Hammer(this.el[0]).on("swipeleft", function() {
            clearInterval(self.interval);
            nextSlide();
        });
        Hammer(this.el[0]).on("swiperight", function() {
            clearInterval(self.interval);
            if (!self.animating) {
                self.counter = self.counter < 1 ? self.lists.length - 1 : self.counter - 1;
                self.actions().setToCurrent(self.counter);
            }
        });
        if (defaults.navigation) {

            this.el.find('ol li').each(function() {
                Hammer($(this)[0]).on('tap', function(e) {
                    clearInterval(self.interval);
                    if (!self.animating) {
                        self.actions().setToCurrent($(e.currentTarget).index());
                    }
                    e.stopPropagation();
                });
            });
        }

        if (defaults.navigation) {
            Hammer(self.arrows[0]).on('tap', function(e) {
                if (!self.animating) {
                    if (e.target.className.indexOf('right') > -1) {
                        clearInterval(self.interval);
                        nextSlide();
                    } else {
                        clearInterval(self.interval);
                        self.counter = self.counter < 1 ? self.lists.length - 1 : self.counter - 1;
                        self.actions().setToCurrent(self.counter);
                    }
                    e.stopPropagation();
                }
            });
        }
        if (defaults.auto) {
            this.interval = setInterval(nextSlide, defaults.timer * 1000);
        }
    }


    return new Etalage($(this));
};


module.exports = $;