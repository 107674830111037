$ = require('../bower_components/jquery/dist/jquery');

$.fn.tooltipper = function(options) {
    var defaults, options, Tips;

    defaults = $.extend({

    }, options);

    Tips = function(el) {
        this.$el = $(el);
        this.isTitle = this.$el.attr('title').length > 0 || this.$el.attr('data-title').length > 0;
        if (this.isTitle) {
            this.cacheTitle = this.$el.attr('data-title', this.$el.attr('title'));
        }
        this.$el.removeAttr('title');
        this.controllers();
    };

    Tips.prototype.positionTip = function() {
        var l = this.$el.offset().left,
            t = this.$el.offset().top;

        this.$tip.css({
            left: ((l + this.$tip.width() + 20) > $(window).width()) ? l - this.$tip.width() : l,
            top: (t - this.$tip.height() - 20)
        });
    };

    Tips.prototype.createTip = function() {
        var title = this.$el.attr('data-title').replace(/#/, '@');

        $('body').append([
            '<div class="simpleTooltip">',
            '<span>', title, '</span>',
            '</div>'
        ].join(''));

        this.$tip = $('.simpleTooltip');
    };

    Tips.prototype.destroyTip = function() {
        $('.simpleTooltip').remove();
    };

    Tips.prototype.controllers = function() {
        var self = this,
            mousemove;
        if (!this.isTitle) {
            return;
        }

        mousemove = function() {
            self.positionTip();
        };

        this.$el.hover(function() {
            self.createTip();
            self.$el.mousemove(mousemove);
        }, function() {
            self.destroyTip();
            self.$el.unbind('mousemove');
        });


    };

    this.each(function() {
        new Tips(this);
    });
};

module.exports = $;