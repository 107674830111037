var $ = require('./../bower_components/jquery/dist/jquery.js');

$.fn.responsiveNav = function(options) {
    var defaults, NAV;

    defaults = $.extend({
        customClass: ''
    }, options);

    NAV = function(el) {
        this.el = el;
        this.$el = $(el);
        this.controllers();
    };

    NAV.prototype.controllers = function() {
        var self = this;
        if ($(window).width() < 630) {
            this.el.addEventListener('touchend', function(e) {
                self.toggleMenu(e);
            }, false);
            this.$el.on('click', function(e) {
                self.toggleMenu(e);
            });

            this.$el.find('a').each(function() {
                var el = $(this)[0];
                el.addEventListener('touchend', function(e) {
                    e.stopPropagation();
                }, false);

                $(el).on('click', function(e) {
                    e.stopPropagation();
                });
            });

            this.$el.addClass(defaults.customClass);
        } else {
            this.$el.click(function(e) {
                self.toggleMenu(e);
            });
        }
    };

    NAV.prototype.toggleMenu = function(e) {
        var el = $(e.currentTarget);
        el[(el.hasClass('show') ? 'remove' : 'add') + 'Class']('show');
    };

    this.each(function() {
        new NAV(this);
    });
};

module.exports = $;